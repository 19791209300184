import React, { useState, useEffect } from 'react'
import { IconSearch, LogoQlueWhite, XSign } from '@qlue/icon-kit'
import { navigate, Link } from 'gatsby'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  Wrapper,
  BaseHeader,
  Fixed,
  BaseButton,
  Menus,
  HamburgerContainer,
  HamburgerIcon,
  MobileWrapper,
  DropdownButton,
  DropdownMenu,
  Dropdown,
  Li,
  StyledInput,
  InputSearch,
  SearchIcon,
  CloseIcon,
  DesktopWrapper
} from './styles'
import { Button, MobileOnly } from 'components'
import IndonesianFlag from 'media/images/flag-id.png'
import EnglandFlag from 'media/images/flag-uk.png'
import JapanFlag from 'media/images/flag-jp.png'
import { LANGUAGE_SOURCE, useLanguage } from 'utils/useLanguage'
import { isBrowser } from 'utils'
import qs from 'query-string'
import { generateElementID, HEADER_ID_PREFIX } from 'utils/generateElementID'

const dashboardURL = process.env.GATSBY_DASHBOARD_URL
const articleURL = process.env.GATSBY_ARTICLE_URL

function useMenu() {
  const { formatMessage } = useIntl()

  function textToTitleCase(text) {
    return text
      .split(' ')
      .map((text) => text.toLowerCase())
      .map((text) => {
        if (!text.includes('and')) {
          return text.charAt(0).toUpperCase() + text.slice(1)
        } else return text
      })
      .join(' ')
  }

  const MENUS = [
    { to: '/', name: formatMessage({ id: '58b782d18' }) },
    {
      to       : '#',
      name     : formatMessage({ id: '5d74f1187' }),
      position : 'right',
      children : [
        {
          to   : '/solution/smart-workforce-management',
          name : textToTitleCase(formatMessage({ id: '8e44f019d' }))
        },
        {
          to   : '/solution/human-detection-and-recognition',
          name : textToTitleCase(formatMessage({ id: '2bdcfb17a' }))
        },
        {
          to   : '/solution/vehicle-detection-and-recognition',
          name : textToTitleCase(formatMessage({ id: 'c9b8e2233' }))
        }
      ]
    },
    {
      to   : '/pricing',
      name : formatMessage({ id: 'd3ceb1bed' })
    },
    {
      to   : articleURL,
      name : formatMessage({ id: '125130f88' })
    }
  ]

  return { MENUS }
}

function Header({ bgColor, location = { search: '' } }) {
  const intl = useIntl()
  const { token: tokenDashboard } = useSelector((state) => state.tokenDashboard)
  const { MENUS } = useMenu()
  const [openHamburger, setOpenHamburger] = useState(false)
  const [isInputFocus, setIsInputFocus] = useState(false)

  const [keyword, setKeyword] = useState('')

  const [openDropdown, setOpenDropdown] = useState('')
  const handleClickDropdown = (event, dropdown) => {
    event.preventDefault()
    if (openDropdown === dropdown) {
      setOpenDropdown('')
    } else {
      setOpenDropdown(dropdown)
    }
  }

  useEffect(() => {
    function onClickWindow() {
      if (openDropdown) setOpenDropdown('')
    }

    isBrowser && window.addEventListener('click', onClickWindow)
    return () => window.removeEventListener('click', onClickWindow)
  }, [openDropdown])

  const queryParams = location.search
  const search = qs.parse(queryParams).search

  useEffect(() => {
    if (search) {
      setKeyword(search)
    } else setKeyword('')
  }, [search])

  return (
    <BaseHeader>
      <Fixed bgColor={ bgColor }>
        <Wrapper className={ openHamburger ? 'open' : '' }>
          <MobileWrapper>
            <LogoQlueWhite id={ generateElementID(HEADER_ID_PREFIX, 'logoqlue') } className="pointer" onClick={ () => navigate('/') } />
            <HamburgerContainer onClick={ () => setOpenHamburger(!openHamburger) }>
              <HamburgerIcon className="hamburger-icon" />
            </HamburgerContainer>
          </MobileWrapper>
          <Menus className="menus">
            <MobileOnly style={{ width: '100%' }}>
              <form
                onSubmit={ (e) => {
                  if (keyword) {
                    e.preventDefault()
                    navigate(`/?search=${keyword}`)
                    setOpenHamburger(false)
                  } else {
                    if (search) navigate('/')
                    else e.preventDefault()
                  }
                } }
              >
                <StyledInput>
                  <InputSearch
                    type="search"
                    value={ keyword }
                    onChange={ (e) => setKeyword(e.target.value) }
                    onFocus={ () => setIsInputFocus(true) }
                    onBlur={ () => setIsInputFocus(false) }
                    placeholder="Search"
                  />
                  <SearchIcon
                    isKeyword={ keyword }
                    isInputFocus={ isInputFocus }
                    onClick={ () => navigate(`/?search=${keyword}`) }
                  >
                    <IconSearch />
                  </SearchIcon>

                  <CloseIcon
                    isDisplay={ keyword && !isInputFocus }
                    isInputFocus={ isInputFocus }
                    onClick={ () => setKeyword('') }
                  >
                    <XSign />
                  </CloseIcon>
                </StyledInput>
              </form>
            </MobileOnly>

            { MENUS.map(({ to, name, icon, children, position }, i) => (
              <RenderMenu
                key={ i }
                position={ position }
                to={ to }
                name={ name }
                isInputFocus={ isInputFocus }
                icon={ icon }
                child={ children }
                open={ openDropdown }
                onClick={ handleClickDropdown }
              />
            )) }

            <Languages isInputFocus={ isInputFocus } />
            <DesktopWrapper isInputFocus={ isInputFocus }>
              <li>
                <form
                  onSubmit={ (e) => {
                    if (keyword) {
                      e.preventDefault()
                      navigate(`/?search=${keyword}`)
                    } else {
                      if (search) navigate('/')
                      else e.preventDefault()
                    }
                  } }
                >
                  <StyledInput>
                    <InputSearch
                      id={ generateElementID(HEADER_ID_PREFIX, 'fieldsearch') }
                      type="search"
                      value={ keyword }
                      onChange={ (e) => setKeyword(e.target.value) }
                      onFocus={ () => setIsInputFocus(true) }
                      onBlur={ () => setIsInputFocus(false) }
                      placeholder="Search"
                    />
                    <SearchIcon
                      isKeyword={ keyword }
                      isInputFocus={ isInputFocus }
                      onClick={ () => navigate(`/?search=${keyword}`) }
                    >
                      <IconSearch />
                    </SearchIcon>

                    <CloseIcon
                      isDisplay={ keyword && !isInputFocus }
                      isInputFocus={ isInputFocus }
                      onClick={ () => setKeyword('') }
                    >
                      <XSign />
                    </CloseIcon>
                  </StyledInput>
                </form>
              </li>
            </DesktopWrapper>
            <li>
              <BaseButton>
                { !tokenDashboard && (
                  <Link to="/register">
                    <Button id={ generateElementID(HEADER_ID_PREFIX, 'buttonsignup') } type="warning" style={{ whiteSpace: 'nowrap' }}>
                      { intl.formatMessage({ id: 'fc164633f' }) }
                    </Button>
                  </Link>
                ) }
                <a href={ dashboardURL }>
                  <Button id={ generateElementID(HEADER_ID_PREFIX, 'buttonlogin') } type={ tokenDashboard ? 'warning' : 'light' } style={{ whiteSpace: 'nowrap' }}>
                    { tokenDashboard ? intl.formatMessage({ id: '873ce8458' }) : intl.formatMessage({ id: '1d5ab5e37' }) }
                  </Button>
                </a>
              </BaseButton>
            </li>
          </Menus>
        </Wrapper>
      </Fixed>
    </BaseHeader>
  )
}

function RenderMenu({ to, name, isInputFocus, icon, child, open, onClick, position = 'left' }) {
  return child ? (
    <Dropdown isInputFocus={ isInputFocus } style={{ whiteSpace: 'nowrap' }} id={ generateElementID(HEADER_ID_PREFIX, 'menu') }>
      <DropdownButton id={ generateElementID(HEADER_ID_PREFIX, 'menu') } onClick={ (e) => onClick(e, name) }>
        { name } { icon ? <img src={ icon } /> : null }
      </DropdownButton>
      <DropdownMenu
        id={ generateElementID(HEADER_ID_PREFIX, 'dropdown') }
        position={ position }
        className={ open === name ? 'open' : '' }
        isInputFocus={ isInputFocus }
      >
        { child.map(({ to: cTo, name: cName, icon: cIcon }, j) => (
          <Link key={ j } to={ cTo } className="dropdown-item">
            { cName } { cIcon ? <img src={ cIcon } /> : null }
          </Link>
        )) }
      </DropdownMenu>
    </Dropdown>
  ) : (
    <Li isInputFocus={ isInputFocus } >
      <Link id={ generateElementID(HEADER_ID_PREFIX, 'menu') } to={ to }>{ name }</Link>
    </Li>
  )
}

// NOTE: currently comment out this component to prevent husky error,
// please uncomment this component when we use <Languanges/> on header
function Languages({ isInputFocus }) {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const toggleOpenMenu = () => setMenuOpen((val) => !val)
  const { lang, setLang } = useLanguage()
  const HIDE_LANGUAGE = { jp: false }

  const DATA = {
    id: {
      name : 'Indonesian',
      icon : IndonesianFlag
    },
    en: {
      name : 'English',
      icon : EnglandFlag
    },
    jp: {
      name : 'Japanese',
      icon : JapanFlag
    }
  }

  useEffect(() => {
    function onClickWindow(e) {
      if (isMenuOpen) setMenuOpen(false)
    }

    isBrowser && window.addEventListener('click', onClickWindow)
    return () => window.removeEventListener('click', onClickWindow)
  }, [isMenuOpen])

  return (
    <Dropdown id={ generateElementID(HEADER_ID_PREFIX, 'dropdownbahasa') } isInputFocus={ isInputFocus } style={{ cursor: 'pointer' }} onClick={ toggleOpenMenu }>
      <DropdownButton>
        <img src={ DATA[lang].icon } alt={ DATA[lang].name } style={{ width: '2rem' }} />
      </DropdownButton>
      <DropdownMenu className={ isMenuOpen && 'open' }>
        { Object.keys(LANGUAGE_SOURCE)
          .filter((language) => !HIDE_LANGUAGE[language])
          .map((language) => (
            <a
              style={{ cursor: 'pointer' }}
              key={ language }
              className='dropdown-item'
              onClick={ () => {
                setLang(language)
                sessionStorage.setItem('qlue-saas-website-language', language)
              } }
            >
              <span>{ DATA[language].name } </span>
              <img src={ DATA[language].icon } alt={ DATA[language].name } />
            </a>
          )) }
      </DropdownMenu>
    </Dropdown>
  )
}

export default Header
